import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { OrderItem } from "@tm/models"
import { Typography, Button, Icon } from "@tm/components"
import ArticleCell from "../../_shared/article-cell"
import ArticleImageComponent from "../../_shared/ArticleImageComponent"
import ArticleNumbers, { StyledStack } from "../../_shared/ArticleNumbers"

type Props = {
    article: OrderItem
    showArticleImage: boolean
}

export default function OrderItemComponent({ article, showArticleImage }: Props) {
    const { articleInfo } = article
    const { translateText } = useLocalization()
    const { userContext } = useUser()

    function renderPrefix() {
        return (
            <ArticleCell bemModifier="prefix">
                <Button startIcon={<Icon name="down" />} variant="text" disabled />
            </ArticleCell>
        )
    }

    function renderThumbnail() {
        if (!showArticleImage) {
            return null
        }

        return (
            <ArticleCell bemModifier="thumbnail">
                <ArticleImageComponent
                    thumbnailClassName="article__thumbnail"
                    thumbnailUrl={articleInfo.thumbnailUrl || ""}
                    thumbnailDescription={translateText(1503)}
                    internalArticleId={0}
                    enableImageViewer={false}
                />
            </ArticleCell>
        )
    }

    function renderSupplier() {
        const text =
            (articleInfo.dataSupplier && articleInfo.dataSupplier.name) || (articleInfo.vehicleManufacturer && articleInfo.vehicleManufacturer.name)

        return (
            <ArticleCell bemModifier="supplier">
                <Typography title={text || ""} width="9em" marginRight="0.5rem">
                    {text || ""}
                </Typography>
            </ArticleCell>
        )
    }

    function renderNumbers() {
        const articleNumber = articleInfo.articleNumber || articleInfo.dataSupplierArticleNumber || " "

        return (
            <ArticleCell bemModifier="numbers">
                <StyledStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
                    {!userContext?.parameter.hideDealerPartNumber && (
                        <ArticleNumbers wholesalerArticleNumber={articleInfo.wholesalerArticleNumber || " "} />
                    )}
                    {articleInfo.oeArticleNumber && <ArticleNumbers oeArticleNumber={articleInfo.oeArticleNumber} />}
                    {articleNumber && <ArticleNumbers supplierArticleNumber={articleInfo.articleNumber || articleInfo.dataSupplierArticleNumber} />}
                </StyledStack>
            </ArticleCell>
        )
    }

    function renderDescription() {
        let content = articleInfo.description || ""

        // show product group name before article description
        if (articleInfo.productGroup?.name) {
            // if article description is not empty add a separator
            if (content) {
                content = ` | ${content}`
            }

            content = articleInfo.productGroup.name + content
        }

        return (
            <ArticleCell bemModifier="description">
                <Typography title={content} overflow="hidden" textOverflow="ellipsis">
                    {content}
                </Typography>
            </ArticleCell>
        )
    }

    return (
        <div className="article-list">
            <div className="article-list__item article-list__panel">
                {renderPrefix()}
                {renderThumbnail()}
                {renderSupplier()}
                {renderNumbers()}
                {renderDescription()}
            </div>
        </div>
    )
}
