import { equals, TmaHelper } from "@tm/utils"
import { parseInt } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { ReplaceButtonBundle, ListFilter, SearchFilters, FittingPosition, OE } from "@tm/models"
import { useArticleListSorting } from "../../../hooks/useArticleListSorting"
import { useArticleSelectionActions } from "../../../hooks/useArticleSelection"
import { DirectSearchListParams, DirectSearchStartParams } from "../../../models"
import { getOePositionsFromCache } from "../../../helpers"
import { getBundleParams } from "@bundles/parts/utils"

function getStartParamsFromUrl(url: string): DirectSearchStartParams {
    const searchParams = new URLSearchParams(url)
    const oePositionsCacheId = searchParams.get("oePositionsCacheId") ?? undefined

    let searchFilter = SearchFilters.All
    const searchFilterValue = searchParams.get("searchFilter")
    if (searchFilterValue) {
        searchFilter = parseInt(searchFilterValue)
    }

    let fittingSideFilter = 0
    const fittingSideFilterValue = searchParams.get("fittingSideFilter")
    if (fittingSideFilterValue) {
        fittingSideFilter = parseInt(fittingSideFilterValue)
    }

    const isOeReplacement = searchParams.get("isOeReplacement") === "1"

    const replaceButtonBundle = searchParams.get("replaceButtonMicro") as ReplaceButtonBundle | null

    let oeManufacturerIds: number[] = []
    const oeManufacturerIdsValue = searchParams.get("oeManufacturerIds")
    if (oeManufacturerIdsValue) {
        oeManufacturerIds = oeManufacturerIdsValue.split(",")?.map((id) => parseInt(id))
    }

    const forcePartsAlternatives = searchParams.get("forcePartsAlternatives") ?? undefined

    return {
        type: "direct",
        query: searchParams.get("query") ?? "",
        oePositionsCacheId,
        searchFilter,
        fittingSideFilter,
        isOeReplacement,
        replaceButtonBundle,
        oeManufacturerIds,
        forcePartsAlternatives,
    }
}

/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useListParams(): DirectSearchListParams {
    const location = useLocation()

    const sorting = useArticleListSorting()
    const { clear: clearSorting } = sorting

    const [startParams, setStartParams] = useState<DirectSearchStartParams>(() => getStartParamsFromUrl(location.search))

    const [productGroups, setProductGroups] = useState<ListFilter[]>([])
    const [suppliers, setSuppliers] = useState<ListFilter[]>([])
    const [attributes, setAttributes] = useState<ListFilter[]>([])
    const [extendedAssortment, setExtendedAssortment] = useState(getBundleParams().extendedAssortmentDefaultOnDirectSearch || false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [showAvailableSecondary, setShowAvailableSecondary] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [noResult, setNoResult] = useState(false)
    const [isFiltersLoading, setIsFiltersLoading] = useState(false)
    const [fittingPosition, setFittingPosition] = useState<FittingPosition>(FittingPosition.None)

    const [oePositions, setOePositions] = useState<OE.OePosition[]>(() => {
        if (startParams.type === "direct" && startParams.oePositionsCacheId) {
            return getOePositionsFromCache(startParams.oePositionsCacheId)
        }

        return []
    })

    const { selectedOePart, selectedOeNumber } = useMemo(() => {
        const selectedPosition = oePositions.find((x) => x.isSelected)
        if (!selectedPosition) {
            return {
                selectedOePart: undefined,
                selectedPosition: undefined,
            }
        }

        TmaHelper.ArticleListFiltered.ArticleListFiltered.List.OePositionChanged(selectedPosition.number)

        const selectedReplacement = selectedPosition.replacements?.find((x) => x.isSelected)
        const selectedOeReplacementPart = selectedReplacement?.parts?.first() || selectedPosition.parts?.first()

        return {
            selectedOePart: selectedOeReplacementPart,
            selectedOeNumber: selectedPosition,
        }
    }, [oePositions])

    // Relevant for Multiselect, resets on reload, list change or new search
    const { deselectAll } = useArticleSelectionActions()

    useEffect(
        function resetOnStartParamsChanged() {
            const newStartParams = getStartParamsFromUrl(location.search)
            deselectAll()

            if (!equals(newStartParams, startParams)) {
                setStartParams(newStartParams)
                setAttributes((prev) => (prev.length ? [] : prev))
                setProductGroups((prev) => (prev.length ? [] : prev))
                setSuppliers((prev) => (prev.length ? [] : prev))
                setExtendedAssortment(false)
                clearSorting()
            }
        },
        [deselectAll, location, startParams, clearSorting]
    )

    const toggleProductGroup = useCallback((filter: ListFilter) => {
        setProductGroups((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleSupplier = useCallback((filter: ListFilter) => {
        setSuppliers((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleAttribute = useCallback((attribute: ListFilter) => {
        setAttributes((state) => {
            const existingIndex = state.findIndex((attr) => attr.id === attribute.id && attr.value === attribute.value)

            if (existingIndex === -1) {
                return [...state, attribute]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const changeOePosition = useCallback((position: OE.OePosition, replacement?: OE.OeNumber) => {
        setOePositions((prev) => {
            if (prev.some((pos) => pos.isSelected && pos === position && pos.replacements?.some((repl) => repl.isSelected && replacement === repl))) {
                return prev
            }
            return prev.map((pos) => {
                if (pos.number === position.number && pos.description === position.description) {
                    return {
                        ...pos,
                        isSelected: true,
                        replacements: pos.replacements?.map((repl) => ({
                            ...repl,
                            isSelected: replacement && repl.number === replacement.number,
                        })),
                    }
                }
                return { ...pos, isSelected: false, replacements: pos.replacements?.map((repl) => ({ ...repl, isSelected: false })) }
            })
        })
    }, [])

    const handleChangeExtendedAssortment = useCallback((checked: boolean) => setExtendedAssortment(checked), [])
    const setAvailability = useCallback((checked: boolean) => setShowAvailable(checked), [])
    const setAvailabilitySecondary = useCallback((checked: boolean) => setShowAvailableSecondary(checked), [])

    const nextPage = useCallback(() => {
        setPageIndex((index) => index + 1)
    }, [])

    return {
        listType: "direct",
        startParams,
        pageIndex,
        productGroups,
        suppliers,
        attributes,
        extendedAssortment,
        showAvailable,
        showAvailableSecondary,
        noResult,
        isFiltersLoading,
        oePositions,
        selectedOePart,
        selectedOeNumber,
        fittingPosition,
        changeOePosition,
        setProductGroups,
        setSuppliers,
        setAttributes,
        toggleProductGroup,
        toggleSupplier,
        toggleAttribute,
        setExtendedAssortment: handleChangeExtendedAssortment,
        setAvailability,
        setAvailabilitySecondary,
        setNoResult,
        setIsFiltersLoading,
        setFittingPosition,
        nextPage,
        sorting,
    }
}
