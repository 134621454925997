import { BonusPoints, Button, ButtonProps, IconButton, LinkButton, Stack } from "@tm/components"
import { Component, Fragment, ReactNode } from "react"

import {
    WithUserContextProps,
    WithUserSettingsProps,
    WithWorkTaskProps,
    withUserContext,
    withUserSettings,
    withWorkTask,
} from "@tm/context-distribution"
import {
    Badge,
    BonusSystemImage,
    ButtonLayout,
    Checkbox,
    Icon,
    Image,
    Loader,
    MessageInline,
    MoreMenu,
    MoreMenuItem,
    Text,
    Tooltip,
} from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import {
    Article,
    ArticleAttribute,
    ArticleInfo,
    ArticleReference,
    ArticleReferenceType,
    ArticleSearchHitInfo,
    AttributeComparisonModel,
    ReplaceButtonBundle,
    ConfigParams,
    EArticleModificationState,
    ErpInformation,
    IArticleItemTemplate,
    IMicros,
    ModificationState,
    RepairTimeProvider,
    RequestArticleDetailsPayload,
    SearchFilters,
    SystemType,
    TmaEModule,
    UserModuleType,
    channel,
    ECounterType,
} from "@tm/models"
import Morpheus, { ComponentIdentifier, TemplateRenderer, WithMicroProps, importMicro } from "@tm/morpheus"
import {
    TmaHelper,
    bindSpecialReactMethods,
    concat,
    encodeUniqueId,
    getArticleBonusSystemsWithPoints,
    getArticleBonusSystemsWithoutPoints,
    getRepairTimeProviders,
    isPseudoArticle,
} from "@tm/utils"
import { SearchType } from "../../../business"

import { printArticleDetails } from "../../../data/repositories"
import { getSearchFilterText } from "../../../helper"
import { getBundleParams } from "../../../utils"
import { getArticleUniqueId } from "../../list/business/helpers"
import ArticleCell from "../article-cell"
import ArticleImageComponent, { Props as ArticleImageComponentProps } from "../ArticleImageComponent"

import ArticleNumbers, { StyledStack } from "../ArticleNumbers"
import { createPrintArticleDetailsRequest } from "../helper"
import AdditionalInformation from "./additional-information"
import ArticleReferences from "./article-references"
import ArticleAttributes from "./attribute-group"
import { ArticleNote } from "./note"
import { NoteActionButton } from "./note/note-action-button"
import { ReferenceLink } from "./reference-link"
import { WrappedStack } from "./styled"
import SupplierComponent from "./supplier"
import { AvailibilityHideWrapper } from "../../list/components/AvailibilityHideWrapper"
import { ListState } from "../../list/business"
import { ReplaceButton } from "./replaceButton"

type ICells =
    | "prefix"
    | "attributes"
    | "references"
    | "information"
    | "documents"
    | "additionalButtons"
    | "erpMemo"
    | "messages"
    | "supplierName"
    | "additionalActions"

export type Props = WithMicroProps<IMicros> &
    LocalizationProps &
    WithUserSettingsProps &
    WithUserContextProps &
    WithWorkTaskProps & {
        state?: ListState
        article: Article
        hiddenCells?: Partial<Record<ICells, boolean | undefined>>
        renderCustomErpInformation?(): ReactNode
        customerId?: string
        className?: string
        foundBySearchTerm?: string
        hideVehicleDescriptions?: boolean
        hitInfos?: { [key: string]: Array<ArticleSearchHitInfo> }
        ignoreAttributeKey?: boolean
        isCompact?: boolean
        isSelected?: boolean
        isDataSupplierFilterActive?: boolean
        partToReplaceId?: string
        previouslyOrdered?: boolean
        productGroupAsDescription?: boolean
        rtProviders?: Array<RepairTimeProvider>
        selectedArticleAttributes?: Array<string>
        showActions?: boolean
        showAdditionalPrices?: boolean
        showErpPawnItems?: boolean
        showArticleImage?: boolean
        showMissingVehicleConnection?: boolean
        showDocumentsInline?: boolean
        showDocumentsInCompact?: boolean
        showReferenceLinksInCompact?: boolean
        openDocumentsAsModal?: boolean
        openReferenceLinksAsModal?: boolean
        vehicleEngineCode?: string
        vehicleId?: string
        vehicleRecordsComparisons?: Array<AttributeComparisonModel>
        workTaskId?: string
        canFilterArticleAttributes: boolean
        advertisementCategoryId?: string
        id: string
        isDisabled?: boolean

        getArticleSearchHitInfo?(article: Article): void
        getRepairTimesUrl?(article: Article, rtProviders: Array<RepairTimeProvider> | RepairTimeProvider): string | undefined
        onArticleAttributeSelect?(article: Article, attribute: ArticleAttribute): void
        onAddToBasket?(article: Article): void
        onArticleSelect?(articleUniqueId: string): void
        onDataSupplierClick?(dataSupplierId: number): void
        onRequestArticleDetails?(request: RequestArticleDetailsPayload): void
        onRequestArticleDirectSearch?(query: string, searchFilter?: SearchFilters): void
        onShowArticleFeedback?(article: Article): void
        onToggleCollapse?(key: string, compact: boolean): void

        renderBuyActions?(article: Article, showButtonText: boolean): ReactNode
        renderCustomIcons?(article: Article): ReactNode
        renderCustomSeason?(article: Article): ReactNode
        onRequestArticleAlternatives?(article: Article): void
        renderAdditionalDescription?(): ReactNode
        renderTotalPrice?(): ReactNode

        hideCostEstimationButton?: boolean
        hideAlternativesIcon?: boolean

        replaceButtonBundle?: ReplaceButtonBundle

        searchType?: SearchType

        partErpData: ErpInformation | undefined

        isLoading?: boolean
    }

type State = {
    collapsedInformation: Array<number>
    isMoreMenuLoading: boolean
    isPrintLoading: boolean
    noteActive: boolean
    references: ArticleReference[]
    additionalReferences: ArticleReference[]
    hasReferences: boolean
    hasInformation: boolean
    tmaOrigin: TmaEModule | undefined
}

@importMicro
class ArticleItem extends Component<Props, State> implements IArticleItemTemplate, TemplateRenderer {
    rendererIdentifier: ComponentIdentifier

    isRedesignSystem: boolean

    onRequestArticleDirectSearch?(query: string, searchFilter?: SearchFilters): void

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            collapsedInformation: [],
            isMoreMenuLoading: false,
            isPrintLoading: false,
            noteActive: false,
            references: this.props.article.references.filter((x) => !x.modificationState),
            additionalReferences: this.props.article.references.filter((x) => x.modificationState),
            hasReferences: this.hasReferences(),
            hasInformation: this.hasInformation(),
            tmaOrigin: TmaHelper.DeterminePartsListOriginByRouteKey(this.props.state?.routeKey),
        }

        this.rendererIdentifier = {
            bundle: "parts",
            component: "article-item",
        }
        this.isRedesignSystem = props.userContext?.system.systemType === SystemType.Redesign
        this.onRequestArticleDirectSearch = getBundleParams().disableArticleListAccess ? undefined : this.props.onRequestArticleDirectSearch
    }

    isInformationCollapsed(informationIdx: number, state?: State): boolean {
        return (state || this.state).collapsedInformation.indexOf(informationIdx) !== -1
    }

    showArticleFeedback(): boolean {
        if (getBundleParams().articleFeedbackPath && this.props.onShowArticleFeedback && !getBundleParams().enableMVCFeedbackOnDetails) {
            switch (getBundleParams().articleFeedbackDisplayBehavior) {
                case "only-trader-articles":
                    return !!this.props.article.traderArticleNo
                default:
                    return true
            }
        }
        return false
    }

    handleToggleInformation(informationIdx: number) {
        this.setState((prevState) => {
            return {
                collapsedInformation: this.isInformationCollapsed(informationIdx, prevState)
                    ? prevState.collapsedInformation.filter((x) => x !== informationIdx)
                    : [...prevState.collapsedInformation, informationIdx],
            }
        })
    }

    handleToggleCollapse() {
        this.props.onToggleCollapse?.(this.props.id, !this.props.isCompact)
    }

    handleToggleCollapseWithTmaEvent = () => {
        this.handleToggleCollapse()
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListExpandArticle, this.props.isCompact)
    }

    handleExpand() {
        if (!this.props.isCompact) {
            return
        }
        this.handleToggleCollapse()
    }

    handleArticleNumbersClick() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaTraderArticleNumber)
        this.handleDetailsButtonClick()
    }

    handleDetailsButtonClick(subPage?: string, scrollTo?: string) {
        const { article, foundBySearchTerm, partToReplaceId } = this.props

        // show directly partslist by opening pseudo articles details
        if (!subPage && article.isPartsListAvailable) {
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaDetailsButton)
            subPage = "partslist"
        }

        this.props.onRequestArticleDetails?.({
            article: {
                productGroupId: article.productGroup.id,
                supplierId: article.supplier.id,
                supplierArticleNo: article.supplierArticleNo,
            },
            vehicleLinkageId: article.vehicleLinkageId,
            subPage,
            scrollTo,
            foundBySearchTerm,
            initialQuantity: article.quantity,
            partToReplaceId,
            disableAddToBasket: !!article.referencedArticleModification?.isRemovedReferencedArticle,
        })
    }

    handleThumbnailClick() {
        const { article, vehicleId } = this.props
        TmaHelper.Article.Parts.ImageClick.Click(article, vehicleId ?? this.props.workTask?.vehicle?.id)
    }

    handleDetailsButtonPartsList() {
        this.handleDetailsButtonClick("partslist")
    }

    handleDetailsButtonAccessories() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAccessoryList)
        this.handleDetailsButtonClick("accessories")
    }

    handleDetailsButtonPdfFIle() {
        this.handleDetailsButtonClick("documents")
    }

    handleDetailsButtonStocks() {
        this.handleDetailsButtonClick("stocks")
    }

    handleDetailsButtonAlternativeArticles() {
        this.handleDetailsButtonClick("alternative-articles")
    }

    handleDetailsButtonLinkedArticles() {
        this.handleDetailsButtonClick("stocks", "linked")
    }

    handleDetailsButtonPrices() {
        this.handleDetailsButtonClick("stocks", "prices")
    }

    handleDetailsButtonTeccom(useSpecialProcurement?: boolean) {
        if (useSpecialProcurement) {
            this.handleDetailsButtonClick("special-procurement")
        } else {
            this.handleDetailsButtonClick("stocks", "teccom")
        }
    }

    handleDetailsButtonPawnItems() {
        this.handleDetailsButtonClick("pawn-articles")
    }

    handleShowRelatedRepairTimes() {
        const { rtProviders } = this.props

        if (rtProviders?.length && this.props.getRepairTimesUrl) {
            const url = this.props.getRepairTimesUrl(this.props.article, rtProviders)

            if (url) {
                Morpheus.showView("1", url)
            }
        }
    }

    handleShowNote() {
        this.setState((prev) => ({ noteActive: !prev.noteActive }))
    }

    handleMenuItemSelect(item: MoreMenuItem) {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleFurtherOptions)
        switch (item.id) {
            case 1: {
                this.handleRequestAlternativeArticles()
                break
            }
            case 2: {
                this.handleDetailsButtonClick("partslist")
                break
            }
            case 3: {
                this.handleDetailsButtonClick("accessories")
                break
            }
            case 4: {
                this.handleDetailsButtonClick("documents")
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDocuments)
                break
            }
            case 5: {
                this.handleShowRelatedRepairTimes()
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleLabourtimes)
                break
            }
            case 6: {
                this.handleShowArticleFeedback()
                break
            }
            case 7: {
                this.handleShowNote()
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleNotes)
                break
            }
            case 8: {
                this.handlePrintButtonClick()
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticlePrint)
                break
            }
            default:
                break
        }
    }

    handleRequestAlternativeArticles() {
        this.props.onRequestArticleAlternatives?.(this.props.article)
    }

    handleShowArticleFeedback() {
        this.props.onShowArticleFeedback?.(this.props.article)
    }

    handlePrintButtonClick() {
        const {
            article,
            localization: { translateText },
            workTaskId,
        } = this.props

        if (!article) {
            return
        }

        const vehicle = channel("WORKTASK", workTaskId).last(1, "VEHICLE/LOADED")?.[0]?.content

        this.setState({ isMoreMenuLoading: true, isPrintLoading: true })
        printArticleDetails(
            createPrintArticleDetailsRequest(
                article,
                vehicle?.tecDocTypeId,
                vehicle?.vehicleType,
                vehicle?.registrationNo,
                vehicle?.registrationTypeId
            ),
            translateText
        )
            .then(() => {
                this.setState({ isMoreMenuLoading: false, isPrintLoading: false })
            })
            .catch(() => this.setState({ isMoreMenuLoading: false, isPrintLoading: false }))
    }

    handleAttributeSelect(attribute: ArticleAttribute) {
        this.props.onArticleAttributeSelect?.(this.props.article, attribute)
    }

    handleReferenceSelect(reference: ArticleReference) {
        const { onRequestArticleDirectSearch } = this

        switch (reference.referenceType) {
            case ArticleReferenceType.ReplacementArticleNo:
            case ArticleReferenceType.ReplacedByArticleNo:
                this.handleOpenArticleDetailsByReference(reference)
                break

            case ArticleReferenceType.UtilityNo:
                TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.UtilityNoClick(reference.referenceNo)

                onRequestArticleDirectSearch?.(
                    reference.referenceNo,
                    SearchFilters.UtilityNo | SearchFilters.OeReference | SearchFilters.TradeReference
                )
                break

            case ArticleReferenceType.EuropeanArticleNo:
                onRequestArticleDirectSearch?.(reference.referenceNo, SearchFilters.EuropeanArticleNumber | SearchFilters.TradeReference)
                break

            case ArticleReferenceType.OeReferenceNo:
                onRequestArticleDirectSearch?.(reference.referenceNo, SearchFilters.OeAndTradeReference)
                break

            default:
                onRequestArticleDirectSearch?.(reference.referenceNo, SearchFilters.All)
                break
        }
    }

    handleShowMoreReferences() {
        this.handleDetailsButtonClick("references")
    }

    handleOpenArticleDetailsByReference(reference: ArticleReference) {
        const { article } = this.props

        // request article details page with product group and supplier id of parent article,
        // but supplier article number of the clicked reference
        this.props.onRequestArticleDetails?.({
            article: {
                productGroupId: article.productGroup.id,
                supplierId: article.supplier.id,
                supplierArticleNo: reference.referenceNo,
            },
        })
    }

    handleSelect() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleCheckbox, !this.props.isSelected)
        this.props.onArticleSelect?.(getArticleUniqueId(this.props.article))
    }

    handleDocumentClick(url: string) {
        if (!this.props.workTaskId) {
            return
        }
        const workTaskId = encodeUniqueId(this.props.workTaskId)
        Morpheus.showView("1", `/${workTaskId}^/modal/external/?url=${url}`)
    }

    handleAddFirstArticleToBasket(articles: Article[]) {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListAddToBasket)
        if (articles[0]) {
            this.props.onAddToBasket?.(articles[0])
        }
    }

    renderPrefix() {
        const nothingToExpand = !this.hasCompactAttributes() && !this.hasReferences() && !this.hasInformation()
        if (this.props.hiddenCells?.prefix) {
            return null
        }
        return (
            <ArticleCell bemModifier="prefix">
                <IconButton
                    onClick={this.handleToggleCollapseWithTmaEvent}
                    disabled={nothingToExpand || !this.props.onToggleCollapse}
                    size="small"
                    disableRipple
                >
                    <Icon name={this.props.isCompact ? "down" : "up"} />
                </IconButton>
            </ArticleCell>
        )
    }

    renderThumbnail() {
        const {
            article,
            showArticleImage,
            localization: { translateText },
        } = this.props

        if (!showArticleImage) {
            return
        }

        const hasGraphicalPartsImage: boolean =
            article.isPartsListAvailable && article.existImage && (!!article.imageCoordinates || isPseudoArticle(article))
        const clickable: boolean = article.existImage || hasGraphicalPartsImage

        let className = "article__thumbnail"
        if (clickable) {
            className += " article__thumbnail--clickable"
        }

        const props: Omit<ArticleImageComponentProps, keyof LocalizationProps> = {
            thumbnailClassName: className,
            thumbnailUrl: article.thumbnail,
            internalArticleId: article.internalId,
            vehicleLinkageId: article.vehicleLinkageId,
            enableImageViewer: clickable,
        }

        if (hasGraphicalPartsImage) {
            props.enableImageViewer = false
            props.thumbnailDescription = translateText(1500)
            props.onClick = () => {
                this.handleThumbnailClick()
                this.handleDetailsButtonPartsList()
            }
            props.customThumbnailComponent = <div className="partslist-hint" title={translateText(1535)} />
        } else if (clickable) {
            props.thumbnailDescription = translateText(1503)
            props.onClick = this.handleThumbnailClick
        }

        return <ArticleImageComponent {...props} />
    }

    renderBonusSystems() {
        const { article } = this.props
        const bonusSystems = getArticleBonusSystemsWithoutPoints(article)

        if (!bonusSystems.length) {
            return
        }

        return bonusSystems.map((x) => (
            <BonusSystemImage key={x.id} className="article__bonus-system" id={x.id} name={x.name} imageUrl={x.imageUrl} />
        ))
    }

    renderThumbnailWrapper(withBonusSystems = true) {
        const bonusSystems = withBonusSystems ? this.renderBonusSystems() : undefined
        const thumbnail = this.renderThumbnail()

        if (!bonusSystems && !thumbnail) {
            return
        }

        return (
            <ArticleCell bemModifier="thumbnail">
                {bonusSystems}
                {thumbnail}
            </ArticleCell>
        )
    }

    renderSupplier() {
        const { article, isDataSupplierFilterActive, onDataSupplierClick, renderCustomSeason } = this.props
        if (this.props.hiddenCells?.supplierName) {
            return null
        }
        return (
            <ArticleCell bemModifier="supplier">
                <SupplierComponent
                    supplier={article.supplier}
                    isDataSupplierFilterActive={isDataSupplierFilterActive}
                    onDataSupplierClick={onDataSupplierClick}
                />
                {renderCustomSeason?.(article)}
            </ArticleCell>
        )
    }

    renderNumbers(blankAsFallback = true, rowDirection = false) {
        const { article, userContext } = this.props

        const fallback = blankAsFallback ? " " : undefined
        return (
            <ArticleCell bemModifier="numbers">
                <StyledStack
                    direction={
                        userContext?.parameter.positionChangeEArtNrHArtNr
                            ? rowDirection
                                ? "row-reverse"
                                : "column-reverse"
                            : rowDirection
                            ? "row"
                            : "column"
                    }
                    spacing={rowDirection ? 1 : undefined}
                    alignItems={rowDirection ? "center" : "flex-start"}
                >
                    {article.traderArticleNo && !userContext?.parameter.hideDealerPartNumber && (
                        <ArticleNumbers
                            wholesalerArticleNumber={article.traderArticleNo || fallback}
                            onOpenArticleDetails={this.handleArticleNumbersClick}
                        />
                    )}
                    {article.supplierArticleNo && (
                        <ArticleNumbers
                            supplierArticleNumber={article.supplierArticleNo || fallback}
                            onOpenArticleDetails={this.handleArticleNumbersClick}
                        />
                    )}
                </StyledStack>
            </ArticleCell>
        )
    }

    renderDescription(position?: string) {
        if (position === "top" && getBundleParams().showTraderDescriptionBottom) {
            return (
                <ArticleCell bemModifier="description" /> // sadly this placeholder is necessary, otherwise the layout breaks
            )
        }
        if (position === "bottom" && !getBundleParams().showTraderDescriptionBottom) {
            return
        }

        const {
            article,
            productGroupAsDescription,
            showMissingVehicleConnection,
            localization: { translateText },
            renderAdditionalDescription,
        } = this.props
        const { additionalReferenceArticleInformation } = article

        let content = concat(" | ", productGroupAsDescription ? article.productGroup.name : undefined, article.description)
        content = concat(" - ", content, article.traderDescription)

        return (
            <ArticleCell bemModifier="description">
                {!!additionalReferenceArticleInformation?.headInformation.length && (
                    <div className="article__description article__description--additional tooltip-wrapper">
                        <Tooltip textSize="s" content={translateText(886)}>
                            <Icon name="plus" />
                        </Tooltip>
                        <Text>{additionalReferenceArticleInformation.headInformation.join(" | ")}</Text>
                    </div>
                )}
                <Text className="article__description" title={content}>
                    {content}
                </Text>
                {showMissingVehicleConnection && !article.vehicleLinkageId && (
                    <div className="article__missing-vehicle-connection" title={translateText(889)}>
                        <Icon name="missing-vehicle-connection" size="l" />
                    </div>
                )}
                {renderAdditionalDescription?.()}
            </ArticleCell>
        )
    }

    renderIcons() {
        const {
            localization: { translateText },
            article,
            renderCustomIcons,
        } = this.props

        if (renderCustomIcons) {
            return <ArticleCell bemModifier="icon">{renderCustomIcons(article)}</ArticleCell>
        }

        const { imsIcon } = getBundleParams()

        return (
            <>
                <ArticleCell bemModifier="icon">{this.renderArticleSearchHitInfoButton()}</ArticleCell>
                <ArticleCell bemModifier="icon">{this.renderVehicleDescriptionsButton()}</ArticleCell>
                <ArticleCell bemModifier="icon">
                    {this.hasAdditionalInformation() && (
                        <Tooltip textSize="s" content={translateText(912)}>
                            <Button startIcon={!imsIcon ? <Icon name="trader-icon" /> : null} onClick={this.handleExpand} variant="text">
                                {!!imsIcon && <Image width={24} height={24} url={imsIcon} />}
                            </Button>
                        </Tooltip>
                    )}
                </ArticleCell>
                <ArticleCell bemModifier="icon">{this.renderSale()}</ArticleCell>
                <ArticleCell bemModifier="icon">
                    {/* Don't show if the article is not orderable because the partslist btn will already be shown instead of the basket btn */}
                    {article.showAddToBasket && article.isPartsListAvailable && !this.isRedesignSystem && (
                        <Button
                            startIcon={<Icon name="partslist" />}
                            onClick={this.handleDetailsButtonPartsList}
                            title={translateText(1525)}
                            variant="text"
                        />
                    )}
                </ArticleCell>
                <ArticleCell bemModifier="icon">
                    {article.isAccessoryListAvailable && !this.isRedesignSystem && (
                        <Button
                            startIcon={<Icon name="accessories" />}
                            onClick={this.handleDetailsButtonAccessories}
                            title={translateText(349)}
                            variant="text"
                        />
                    )}
                    {!getBundleParams().showArticleNotesAsButton && this.renderArticleNoteButton(["ghost"])}
                </ArticleCell>
                {this.props.showAdditionalPrices && (
                    <ArticleCell bemModifier="icon">{this.props.renderMicro!("erp", "erp-additional-prices", { data: article })}</ArticleCell>
                )}
                {this.props.showErpPawnItems && !this.isRedesignSystem && (
                    <ArticleCell bemModifier="icon">
                        {this.props.renderMicro!("erp", "erp-info-pawn", {
                            data: article,
                            onClick: this.handleDetailsButtonPawnItems,
                        })}
                    </ArticleCell>
                )}
            </>
        )
    }

    renderBonusPoints() {
        const {
            article,
            localization: { translateText },
        } = this.props
        const bonusSystems = getArticleBonusSystemsWithPoints(article)

        if (!bonusSystems.length) {
            return
        }

        return (
            <ArticleCell bemModifier="bonus-points">
                {bonusSystems.map((x) => (
                    <BonusPoints
                        key={x.id}
                        bonusSystem={{
                            ...x,
                            name: translateText(13113),
                        }}
                    />
                ))}
            </ArticleCell>
        )
    }

    renderErpInformation(className = "", withCell = false) {
        const { specialErpIconsToHandle } = getBundleParams()
        const { article, hideAlternativesIcon } = this.props
        const Cell = withCell ? ArticleCell : Fragment
        const props = withCell ? { className } : {}

        if (this.props.renderCustomErpInformation) {
            return (
                <Cell {...props}>
                    <ArticleCell bemModifier="erp-information">{this.props.renderCustomErpInformation?.()}</ArticleCell>
                </Cell>
            )
        }
        return (
            <Cell {...props}>
                <ArticleCell bemModifier="erp-information">
                    {this.props.renderMicro!("erp", "erp-info", {
                        data: article,
                        hideSpecialIconsFromPrices: true,
                        searchQuery: this.props.foundBySearchTerm,
                        onClick: (useSpecialProcurement?: boolean) => {
                            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAvailability)

                            if (useSpecialProcurement) {
                                this.handleDetailsButtonClick("special-procurement", "#load-teccom")
                            } else {
                                this.handleDetailsButtonClick("stocks")
                            }
                        },
                        onAlternativesClick: !this.isRedesignSystem
                            ? () => {
                                  TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAlternativeArticle)
                                  this.handleDetailsButtonClick("alternative-articles")
                              }
                            : undefined,
                        onReplacementArticlesClick: !this.isRedesignSystem
                            ? () => {
                                  this.handleDetailsButtonClick("replacement-articles")
                              }
                            : undefined,
                        onGraduatedPricesClick: () => {
                            this.handleDetailsButtonPrices()
                        },
                        onStgSafetyDataSheetClick: () => {
                            this.handleDetailsButtonClick("safety-data-sheet")
                        },
                        onTeccomClick: (useSpecialProcurement: boolean) => {
                            if (useSpecialProcurement) {
                                this.handleDetailsButtonClick("special-procurement", "#load-teccom")
                            } else {
                                this.handleDetailsButtonClick("stocks", "teccom")
                            }
                        },
                        onPartnersystemClick: !this.isRedesignSystem
                            ? () => {
                                  this.handleDetailsButtonClick("partnersystems")
                              }
                            : undefined,
                        onSpecialIconClick: (iconName: string) => {
                            if (!specialErpIconsToHandle) {
                                return
                            }

                            switch (iconName) {
                                case specialErpIconsToHandle.externalArticleDocumentsIconName: {
                                    this.handleDetailsButtonClick("documents")
                                    break
                                }

                                case specialErpIconsToHandle.externalArticleReplacementIconName: {
                                    this.handleDetailsButtonClick("replacement-articles")
                                    break
                                }

                                default: {
                                    break
                                }
                            }
                        },
                        hideAlternativesIcon,
                    })}
                </ArticleCell>
            </Cell>
        )
    }

    renderSpecialIcons() {
        return this.props.renderMicro!("erp", "erp-info-special-icons", { data: this.props.article })
    }

    renderPrices(priceNamesInTooltip?: boolean) {
        return (
            <>
                {this.props.renderTotalPrice?.()}
                {this.props.renderMicro!("erp", "erp-info-prices", {
                    data: this.props.article,
                    priceNamesInTooltip,
                    hideSpecialIconsFromPrices: true,
                })}
            </>
        )
    }

    renderAvailability() {
        return this.props.renderMicro!("erp", "erp-info-availability", {
            data: this.props.article,
            onClick: this.handleDetailsButtonStocks,
            onTeccomClick: this.handleDetailsButtonTeccom,
        })
    }

    renderTeccom() {
        return this.props.renderMicro!("erp", "erp-info-teccom", {
            data: this.props.article,
            onClick: this.handleDetailsButtonStocks,
        })
    }

    renderAlternativeArticles() {
        if (!this.isRedesignSystem) {
            return null
        }
        return this.props.renderMicro!("erp", "erp-info-alternatives", {
            data: this.props.article,
            onClick: this.handleDetailsButtonAlternativeArticles,
        })
    }

    renderGraduatedPriceInfo() {
        return this.props.renderMicro!("erp", "erp-info-graduated", {
            data: this.props.article,
            onClick: this.handleDetailsButtonPrices,
        })
    }

    renderQuantityUnit() {
        const isWheels = window.location.pathname.includes("wheels")
        if (isWheels && !this.props.userSettings?.showPurchasePrice) {
            return null
        }
        return this.props.renderMicro!("erp", "erp-info-quantity-unit", { data: this.props.article })
    }

    renderLinkedArticles() {
        if (!this.isRedesignSystem) {
            return null
        }
        return this.props.renderMicro!("erp", "erp-info-pawn", {
            data: this.props.article,
            onClick: this.handleDetailsButtonPawnItems,
        })
    }

    renderArticleNoteButton(layout?: ButtonLayout[], alwaysVisible?: boolean, changeIcons?: boolean, size?: "small" | "medium" | "large") {
        if (!getBundleParams().enableArticleNotes) {
            return null
        }

        return (
            <NoteActionButton
                handleOnNoteClick={this.handleShowNote}
                alwaysVisible={alwaysVisible}
                layout={layout}
                article={this.props.article}
                changeIcons={changeIcons}
                vehicle={this.props.workTask?.vehicle}
                size={size}
            />
        )
    }

    renderAddToCompilations() {
        if (this.props.article.showAddToBasket) {
            const compilationMicro = this.props.renderMicro!("compilations", "add-to-compilation", {
                article: this.props.article,
            })

            if (compilationMicro) {
                return <div className="btn-compilations">{compilationMicro}</div>
            }
        }

        return null
    }

    renderSale() {
        return this.props.renderMicro!("erp", "erp-info-sale", { data: this.props.article })
    }

    renderBuyActions(showButtonText = false) {
        const {
            article,
            vehicleId,
            customerId,
            foundBySearchTerm,
            partToReplaceId,
            localization: { translate, translateText },
            advertisementCategoryId,
            replaceButtonBundle,
            renderMicro,
        } = this.props

        if (this.props.renderBuyActions) {
            return this.props.renderBuyActions(this.props.article, showButtonText)
        }

        if (replaceButtonBundle) {
            return <ReplaceButton part={this.props.article} bundle={replaceButtonBundle} />
        }

        // Show partslist btn like basket btn for pseudo articles with partslist available
        if (!article.showAddToBasket && article.isPartsListAvailable && !this.isRedesignSystem) {
            return (
                <Button
                    startIcon={<Icon name="partslist" />}
                    className="btn--partslist"
                    onClick={this.handleDetailsButtonPartsList}
                    variant="bordered"
                    color="highlight"
                    title={translateText(1525)}
                >
                    {showButtonText ? translate(348) : undefined}
                </Button>
            )
        }

        return renderMicro!("basket", "add-to-basket", {
            data: [article],
            vehicleId,
            customerId,
            foundBySearchTerm,
            onAddToBasket: this.handleAddFirstArticleToBasket, // Only one article was passed to the micro so we can safely use the first one only here.
            buttonText: showButtonText ? translateText(133) : undefined,
            partToReplaceId,
            advertisementCategoryId,
            erpType: "list",
            searchType: this.props.searchType,
            openPopoverOnMultiplePartnerSystems: true,
            origin: this.state.tmaOrigin,
        })
    }

    renderActions() {
        if (this.props.showActions) {
            return (
                <ArticleCell bemModifier="actions">
                    {this.renderBuyActions()}
                    {this.renderAdditionalActions()}
                </ArticleCell>
            )
        }
    }

    renderAdditionalActionsAsRowWithoutMore(hideTeccom?: boolean) {
        const {
            hiddenCells = {},
            article,
            localization: { translate },
            onRequestArticleAlternatives,
            rtProviders = [],
        } = this.props

        if (hiddenCells.additionalActions) {
            return null
        }

        const { combineAlternativeArticlesFromDbAndErp } = Morpheus.getParams<ConfigParams>()
        const { hideAdditionalArticleAlternatives, nextLight, showAdditionalArticleAlternativesAsButton, showArticleFeedbackAsButton } =
            getBundleParams()

        const renderAlternativeArticlesButton = () => {
            if (hideAdditionalArticleAlternatives || showAdditionalArticleAlternativesAsButton) {
                return
            }

            if (combineAlternativeArticlesFromDbAndErp) {
                return (
                    <Button startIcon={<Icon name="alternative-article" />} onClick={() => this.handleDetailsButtonClick("alternative-articles")}>
                        {translate(875)}
                    </Button>
                )
            }

            if (onRequestArticleAlternatives) {
                return (
                    <Button startIcon={<Icon name="search" />} onClick={this.handleRequestAlternativeArticles}>
                        {translate(636)}
                    </Button>
                )
            }
        }

        return (
            <ArticleCell bemModifier="additional-actions">
                <Stack direction="row" spacing={0.5}>
                    <Button onClick={() => this.handleDetailsButtonClick()}>{translate(43)}</Button>
                    {renderAlternativeArticlesButton()}
                    {!!rtProviders.length && !nextLight && (
                        <Button startIcon={<Icon name="repairtimes" />} onClick={this.handleShowRelatedRepairTimes}>
                            {translate(83)}
                        </Button>
                    )}
                    {!!article.isPartsListAvailable &&
                        !this.isRedesignSystem && ( // due to article.existPdf, this will be casted to boolean
                            <Button startIcon={<Icon name="partslist" />} onClick={this.handleDetailsButtonPartsList}>
                                {translate(348)}
                            </Button>
                        )}
                    {!!article.isAccessoryListAvailable &&
                        !this.isRedesignSystem && ( // due to article.existPdf, this will be casted to boolean
                            <Button startIcon={<Icon name="accessories" />} onClick={this.handleDetailsButtonAccessories}>
                                {translate(349)}
                            </Button>
                        )}
                    {!!article.existPdf && ( // this one can be 0 instead of boolean...
                        <Button startIcon={<Icon name="file-pdf" />} onClick={this.handleDetailsButtonPdfFIle}>
                            {translate(350)}
                        </Button>
                    )}

                    {!hideTeccom && this.renderTeccom()}
                    {this.renderAlternativeArticles()}
                    {this.renderLinkedArticles()}
                    {this.renderArticleNoteButton([], true, true)}
                    {this.renderPrintArticleDetails()}

                    {this.showArticleFeedback() && showArticleFeedbackAsButton && (
                        <Button startIcon={<Icon name="feedback" />} key="feedback" onClick={this.handleShowArticleFeedback}>
                            {translate(41)}
                        </Button>
                    )}
                </Stack>
            </ArticleCell>
        )
    }

    renderPrintArticleDetails() {
        const { isPrintLoading } = this.state

        return (
            <div className="print-details-wrapper">
                {isPrintLoading && <Badge title="loading" skin="dark" loading />}
                <Button startIcon={<Icon name="print" />} className="print-details-btn" onClick={this.handlePrintButtonClick}>
                    {this.props.localization.translateText(49)}
                </Button>
            </div>
        )
    }

    renderAdditionalActions() {
        const {
            hiddenCells = {},
            localization: { translate, translateText },
            onRequestArticleAlternatives,
            userContext,
        } = this.props

        if (hiddenCells.additionalActions) {
            return this.renderSelector()
        }

        const {
            hideAdditionalArticleAlternatives,
            hideArticleMoreButton,
            hideArticleRepairTimes,
            nextLight,
            showAdditionalArticleAlternativesAsButton,
            showArticleDocumentsAsButton,
            showArticleFeedbackAsButton,
            showArticleNotesAsButton,
            showArticlePrintAsButton,
            showArticleRepairTimesAsButton,
        } = getBundleParams()

        const menuItems: Array<MoreMenuItem> = []

        if (
            onRequestArticleAlternatives &&
            !Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp &&
            !hideAdditionalArticleAlternatives &&
            !showAdditionalArticleAlternativesAsButton &&
            !this.isRedesignSystem
        ) {
            menuItems.push({ icon: "search", id: 1, text: translateText(636) })
        }

        if (this.props.article.existPdf && !this.props.showDocumentsInline && !showArticleDocumentsAsButton) {
            menuItems.push({ icon: "file-pdf", id: 4, text: translateText(350) })
        }

        if (this.props.rtProviders?.length && !showArticleRepairTimesAsButton && !nextLight && !hideArticleRepairTimes) {
            menuItems.push({ icon: "repairtimes", id: 5, text: translateText(83) })
        }

        if (this.showArticleFeedback() && !showArticleFeedbackAsButton) {
            menuItems.push({ icon: "feedback", id: 6, text: translateText(41) })
        }

        if (userContext.modules?.find((x) => x.type === UserModuleType.ArticleNotes) && !showArticleNotesAsButton) {
            menuItems.push({ icon: "add-note", id: 7, text: translateText(12899) })
        }

        if (!showArticlePrintAsButton) {
            menuItems.push({ icon: "print", id: 8, text: translateText(49) })
        }

        return (
            <>
                {this.renderCostEstimationButton()}
                {this.props.onRequestArticleDetails && <Button onClick={() => this.handleDetailsButtonClick()}>{translate(43)}</Button>}
                {this.renderAddToCompilations()}
                {!hideArticleMoreButton && (
                    <div className="more-menu-wrapper">
                        {this.state.isMoreMenuLoading && <Badge title="loading" skin="dark" loading />}
                        <MoreMenu items={menuItems} onMenuItemSelect={this.handleMenuItemSelect} disabled={!menuItems.length} />
                    </div>
                )}
                {this.renderSelector()}
            </>
        )
    }

    renderSelector() {
        if (!this.props.onArticleSelect) {
            return
        }

        return <Checkbox checked={this.props.isSelected} onToggle={this.handleSelect} />
    }

    renderCostEstimationButton(largeButton = false) {
        if (
            !this.props.workTaskId ||
            !this.props.getRepairTimesUrl ||
            this.props.hideCostEstimationButton ||
            getBundleParams().hideCostEstimationButton
        ) {
            return null
        }

        const { repairTimeProviders } = getRepairTimeProviders()

        // micro add-to-cost-estimate
        return this.props.renderMicro!("basket", "add-to-cost-estimate", {
            data: [this.props.article],
            vehicleId: this.props.vehicleId,
            customerId: this.props.customerId,
            foundBySearchTerm: this.props.foundBySearchTerm,
            repairTimeProviders,
            variant: largeButton ? "large" : "normal",
            getRepairTimesUrl: this.props.getRepairTimesUrl,
            onAddToBasket: this.handleAddFirstArticleToBasket, // Only one article was passed to the micro so we can safely use the first one only here.
            erpType: "list",
            disabled: this.props.isDisabled,
        })
    }

    renderAttributesCell() {
        const { article } = this.props

        if (this.props.hiddenCells?.attributes) {
            return null
        }
        return (
            <ArticleCell bemModifier="attributes">
                <ArticleAttributes
                    isCompact={this.props.isCompact}
                    attributes={article.attributes}
                    selected={this.props.selectedArticleAttributes ?? []}
                    onSelect={this.props.onArticleAttributeSelect ? this.handleAttributeSelect : undefined}
                    vehicleEngineCode={this.props.vehicleEngineCode}
                    ignoreAttributeKey={this.props.ignoreAttributeKey}
                    productGroupId={article.productGroup.id}
                    toggleMoreAttributes={this.props.onToggleCollapse ? this.handleToggleCollapse : undefined}
                    vehicleRecordsComparisons={this.props.vehicleRecordsComparisons ?? []}
                    canFilterArticleAttributes={this.props.canFilterArticleAttributes}
                />
            </ArticleCell>
        )
    }

    renderReferencesCell() {
        if (!this.state.hasReferences) {
            return null
        }
        if (this.props.hiddenCells?.references) {
            return null
        }
        if (this.isRedesignSystem) {
            return null
        }

        return (
            <ArticleCell bemModifier="references">
                <ArticleReferences
                    references={this.state.references}
                    onSelect={this.onRequestArticleDirectSearch ? this.handleReferenceSelect : undefined}
                    onShowMoreReferences={this.handleShowMoreReferences}
                />
            </ArticleCell>
        )
    }

    renderDocumentsCell() {
        const {
            localization: { translate },
            isCompact,
            showDocumentsInline,
            showDocumentsInCompact,
            showReferenceLinksInCompact,
            article: { additionalReferenceArticleInformation, documents },
        } = this.props

        if (this.props.hiddenCells?.documents) {
            return null
        }
        const content = []
        if (showDocumentsInline && documents && (!isCompact || showDocumentsInCompact)) {
            content.push(
                <WrappedStack key="docs">
                    {documents.map((x, index) => {
                        const props: ButtonProps = {}
                        if (this.props.openDocumentsAsModal) {
                            return (
                                <Button key={`doc_${index}`} onClick={this.handleDocumentClick.bind(this, x.url)} size="small">
                                    {x.description}
                                </Button>
                            )
                        }
                        return (
                            <LinkButton key={`doc_${index}`} to={x.url} target="_blank" size="small">
                                {x.description}
                            </LinkButton>
                        )
                    })}
                </WrappedStack>
            )
        }
        if (showReferenceLinksInCompact && additionalReferenceArticleInformation?.links) {
            const links: Array<ArticleInfo> = ([] as Array<ArticleInfo>).concat.apply(
                [],
                additionalReferenceArticleInformation?.links.map((x) => x.member)
            )
            if (links.length) {
                content.push(
                    <div key="links">
                        {links.map((x, idx) => (
                            <ReferenceLink
                                link={x}
                                key={idx}
                                handleOpenInModal={this.props.openDocumentsAsModal ? this.handleDocumentClick.bind(this) : undefined}
                            />
                        ))}
                    </div>
                )
            }
        }

        if (!content.length) {
            return null
        }

        return (
            <ArticleCell bemModifier="documents">
                <div className="article__information-group">
                    <div className="article__information-group__description center-horizontal">
                        <Text>{translate(350)}</Text>
                    </div>
                    {content}
                </div>
            </ArticleCell>
        )
    }

    renderInformation(information: Array<ArticleInfo>, idx: number) {
        if (!information || !information.length || information.every((x) => !x.content)) {
            return
        }

        const isCollapsed = this.isInformationCollapsed(idx)

        let className = "article__information-group"
        if (isCollapsed) {
            className += " article__information-group--collapsed"
        }

        return (
            <div key={idx} className={className}>
                <div className="article__information-group__description center-horizontal">
                    <Button
                        endIcon={<Icon name={isCollapsed ? "down" : "up"} />}
                        onClick={() => this.handleToggleInformation(idx)}
                        size="small"
                        variant="text"
                    >
                        {information[0].description}
                    </Button>
                </div>
                {information.map((x, index) => (
                    <Text key={index} size="s" modifiers="block" className="article__information">
                        {x.content}
                    </Text>
                ))}
            </div>
        )
    }

    renderInformationCell() {
        if (!this.state.hasInformation) {
            return null
        }
        if (this.props.hiddenCells?.information) {
            return null
        }
        const { article } = this.props

        return (
            <ArticleCell bemModifier="information">
                {Object.values([...article.vehicleInformation, ...article.information].groupBy((x) => x.description)).map(this.renderInformation)}
                <AdditionalInformation
                    articleModificationState={article.articleModificationState}
                    isAddedReferencedArticle={article.referencedArticleModification?.isAddedReferencedArticle || false}
                    information={article.additionalReferenceArticleInformation}
                    references={this.state.additionalReferences}
                    onReferenceSelect={this.handleReferenceSelect}
                    hideLinks={this.props.showReferenceLinksInCompact}
                />
            </ArticleCell>
        )
    }

    renderModificationState() {
        const { articleModificationState, referencedArticleModification } = this.props.article
        let icon: string | undefined
        let iconClassName: string | undefined
        let message: ReactNode

        if (articleModificationState === EArticleModificationState.ArticleDisabled) {
            icon = "not"
            iconClassName = "icon--danger"
            message = this.props.localization.translate(887)
        } else if (articleModificationState === EArticleModificationState.VehicleLinkageDisabled) {
            icon = "not"
            iconClassName = "icon--danger"
            message = this.props.localization.translate(890)
        } else if (referencedArticleModification?.isRemovedReferencedArticle) {
            icon = "not"
            iconClassName = "icon--danger"
            message = this.props.localization.translate(13050)
        } else {
            return null
        }

        return (
            <div className="article__status icon-wrapper">
                {icon && <Icon name={icon} className={iconClassName} />}
                <Text>{message}</Text>
            </div>
        )
    }

    handleOpenArticleSearchHitInfos(open: boolean) {
        const { article, hitInfos, getArticleSearchHitInfo } = this.props

        if (open && hitInfos && !hitInfos.hasOwnProperty(article.id)) {
            getArticleSearchHitInfo?.(article)
        }
    }

    renderArticleSearchHitInfoButton() {
        const {
            article,
            hitInfos,
            localization: { translateText },
        } = this.props

        if (!hitInfos) {
            return
        }

        const renderArticleSearchHitInfo = () => {
            if (!hitInfos.hasOwnProperty(article.id)) {
                return <Loader />
            }

            const infos = hitInfos[article.id]

            if (!infos?.length) {
                return <Text>{translateText(163)}</Text>
            }

            return (
                <>
                    <Text key="found-by" modifiers={["block", "sub"]} size="s">
                        {translateText(1388)}:
                    </Text>
                    {infos.map((info, id) => {
                        const textParts = [getSearchFilterText(info.searchFilter, translateText), info.referenceNo, info.manufacturerName]

                        if (info.oeReferenceInfo) {
                            textParts.push(` (${info.oeReferenceInfo})`)
                        }

                        return (
                            <Text key={id} modifiers={["block"]}>
                                {textParts.join(" ")}
                            </Text>
                        )
                    })}
                </>
            )
        }

        return (
            <Tooltip
                style="primary"
                content={renderArticleSearchHitInfo()}
                onChangeVisibility={this.handleOpenArticleSearchHitInfos}
                event="click"
                hideOnOutsideClick
            >
                <div className="hit-info-icon-wrapper" title={translateText(1387)}>
                    <Button startIcon={<Icon name="articlesearch" />} variant="text" />
                </div>
            </Tooltip>
        )
    }

    renderVehicleDescriptionsButton() {
        const {
            hideVehicleDescriptions,
            article: { vehicleDescriptions },
            localization: { translateText },
        } = this.props

        if (hideVehicleDescriptions || !vehicleDescriptions?.length) {
            return
        }

        const renderVehicleDescriptions = () => (
            <>
                <Text modifiers={["block", "sub"]} size="s">
                    {translateText(147)}:
                </Text>
                <div className="accessory-list-vehicle-descriptions__list-wrapper">
                    {vehicleDescriptions.map((info, id) => (
                        <Text key={id} modifiers={["block"]}>
                            {info}
                        </Text>
                    ))}
                </div>
            </>
        )

        return (
            <Tooltip style="primary" content={renderVehicleDescriptions()} event="click" hideOnOutsideClick>
                <div className="accessory-list-vehicle-descriptions__icon-wrapper" title={translateText(754)}>
                    <Icon name="vehicle-reference" />
                </div>
            </Tooltip>
        )
    }

    renderAdditionalButtons() {
        const {
            hiddenCells = {},
            localization: { translate },
        } = this.props

        if (hiddenCells.additionalButtons) {
            return null
        }

        const {
            enableArticleNotes,
            hideAdditionalArticleAlternatives,
            nextLight,
            showAdditionalArticleAlternativesAsButton,
            showArticleDocumentsAsButton,
            showArticleFeedbackAsButton,
            showArticleNotesAsButton,
            showArticlePrintAsButton,
            showArticleRepairTimesAsButton,
        } = getBundleParams()

        const buttons = []

        if (this.props.onRequestArticleAlternatives && !hideAdditionalArticleAlternatives && showAdditionalArticleAlternativesAsButton) {
            buttons.push(
                <Button key="article-alternatives" startIcon={<Icon name="search" />} size="small" onClick={this.handleRequestAlternativeArticles}>
                    {translate(636)}
                </Button>
            )
        }

        if (this.props.article.existPdf && !this.props.showDocumentsInline && showArticleDocumentsAsButton) {
            buttons.push(
                <Button key="documents" startIcon={<Icon name="file-pdf" />} size="small" onClick={() => this.handleDetailsButtonClick("documents")}>
                    {translate(350)}
                </Button>
            )
        }

        if (this.props.rtProviders?.length && showArticleRepairTimesAsButton && !nextLight) {
            buttons.push(
                <Button key="repairtimes" startIcon={<Icon name="repairtimes" />} size="small" onClick={this.handleShowRelatedRepairTimes}>
                    {translate(83)}
                </Button>
            )
        }

        if (this.showArticleFeedback() && showArticleFeedbackAsButton) {
            buttons.push(
                <Button key="feedback" startIcon={<Icon name="feedback" />} size="small" onClick={this.handleShowArticleFeedback}>
                    {translate(41)}
                </Button>
            )
        }

        if (enableArticleNotes && showArticleNotesAsButton) {
            buttons.push(this.renderArticleNoteButton([], true, true, "small"))
        }

        if (showArticlePrintAsButton) {
            buttons.push(
                <Button key="documents" startIcon={<Icon name="print" />} size="small" onClick={this.handlePrintButtonClick}>
                    {translate(49)}
                </Button>
            )
        }

        if (!buttons.length) {
            return null
        }

        return (
            <ArticleCell bemModifier="additional-buttons">
                <Stack direction="row" spacing={0.5}>
                    {buttons}
                </Stack>
            </ArticleCell>
        )
    }

    renderMessages() {
        const {
            article,
            previouslyOrdered,
            renderMicro,
            localization: { translateText },
        } = this.props
        if (this.props.hiddenCells?.messages) {
            return null
        }
        return (
            <ArticleCell bemModifier="messages">
                {previouslyOrdered && <MessageInline size="s" iconSize="m" message="" description={translateText(80)} />}
                {(renderMicro as any)!("dms", "article-info", { part: article })}
            </ArticleCell>
        )
    }

    renderErpMemo() {
        if (this.props.hiddenCells?.erpMemo) {
            return null
        }
        return this.props.renderMicro!("erp", "erp-info-memo", { data: this.props.article })
    }

    renderDefault() {
        return (
            <>
                {this.renderModificationState()}
                <div className={this.getQualifiedClassName()}>
                    {/* Nur der Knopf zum auf und zu klappen */}
                    {this.renderPrefix()}

                    {/* Thumbnail + optionales Bonus Bild oben drüber */}
                    {this.renderThumbnailWrapper()}

                    {/* Hersteller / Anbieter */}
                    {this.renderSupplier()}

                    {/* Artikelnummer */}
                    {this.renderNumbers()}

                    {/* Artikelbeschreibung */}
                    {this.renderDescription("top")}

                    {/* Icon Container */}
                    {this.renderIcons()}

                    {/* Bonuspunkte */}
                    {this.renderBonusPoints()}

                    {/* Magic Verfügbarkeiten und andere ERP infos */}
                    {this.renderErpInformation()}

                    {/* Action Buttons */}
                    {this.renderActions()}

                    {this.renderAttributesCell()}

                    {this.renderReferencesCell()}

                    {this.renderInformationCell()}

                    {this.renderDocumentsCell()}

                    {this.renderAdditionalButtons()}

                    {this.renderDescription("bottom")}

                    {this.renderErpMemo()}

                    {this.renderMessages()}
                </div>
                {this.renderArticleNotes()}
            </>
        )
    }

    renderArticleNotes() {
        if (getBundleParams().enableArticleNotes) {
            return (
                <ArticleNote
                    vehicle={this.props.workTask?.vehicle}
                    article={this.props.article}
                    onClose={this.handleShowNote}
                    isActive={this.state.noteActive}
                />
            )
        }

        return null
    }

    render() {
        const template = getBundleParams().templates?.articleItem
        let content = null

        if (this.props.isLoading) {
            return null
        }

        if (template) {
            content = Morpheus.renderTemplate(template.bundle, template.template, this)
        }

        if (!content) {
            content = this.renderDefault()
        }

        return (
            <AvailibilityHideWrapper availability={this.props.state?.usedFilters?.availability} article={this.props.article}>
                {content}
            </AvailibilityHideWrapper>
        )
    }

    getQualifiedClassName(withPanel = true): string {
        const { article, isCompact, className: propsClassName } = this.props
        const { articleModificationState } = article

        let className = "article-list__item"
        if (isCompact) {
            className += " article-list__item--compact"
        }

        if (
            articleModificationState === EArticleModificationState.ArticleDisabled ||
            articleModificationState === EArticleModificationState.VehicleLinkageDisabled ||
            article.referencedArticleModification?.isRemovedReferencedArticle
        ) {
            className += " article-list__item--disabled"
        }

        if (propsClassName) {
            className += ` ${propsClassName}`
        }

        if (withPanel) {
            className += " article-list__panel"
        }

        return className
    }

    hasInformation(): boolean {
        return (
            this.hasAdditionalInformation() ||
            this.props.article.information?.some((info) => !!info?.content) ||
            this.props.article.vehicleInformation?.some((info) => !!info?.content)
        )
    }

    hasReferences(): boolean {
        return this.props.article?.references?.some(
            (x) =>
                x &&
                !x.modificationState &&
                (x.referenceType === ArticleReferenceType.UtilityNo ||
                    x.referenceType === ArticleReferenceType.ReplacementArticleNo ||
                    x.referenceType === ArticleReferenceType.ReplacedByArticleNo)
        )
    }

    hasCompactAttributes(): boolean {
        return this.props.article.attributes?.some(
            (attr) => !!attr?.articleAttributes?.length || !!attr?.vehicleAttributes?.length || !!attr?.partsListAttributes?.length
        )
    }

    hasAdditionalInformation(): boolean {
        const { references, additionalReferenceArticleInformation, articleModificationState, referencedArticleModification } = this.props.article

        const hasModificationState =
            articleModificationState === EArticleModificationState.ArticleAdded ||
            articleModificationState === EArticleModificationState.VehicleLinkageAdded
        const hasAdditionalInfo =
            !!additionalReferenceArticleInformation?.textInformation.length || !!additionalReferenceArticleInformation?.links.length
        const hasReferences = references?.some((x) => x && x.modificationState === ModificationState.Added)
        const hasReferencedArticleModification = referencedArticleModification?.isAddedReferencedArticle || false

        return hasModificationState || hasAdditionalInfo || hasReferences || hasReferencedArticleModification
    }
}

export default withUserContext(withUserSettings(withWorkTask(withLocalization(ArticleItem))))
