import { Stack, Select, Typography, SelectItem, useDefaultArticleItemState, MenuItem, SelectChangeEvent, Icon, Box, styled, selectClasses, typographyClasses } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { OeReplacementManufacturer, SearchFilterTypes } from "@tm/models"
import { useOeReplacementContext } from "../ContextProvider"
import { useEffect, useMemo, useState } from "react"
import { useDirectSearchListParamsContext } from "../ContextProvider/ListParams"
import { containsReplacementNumber, normalizeReplacementNumber } from "@bundles/parts/components/list/components/utils"
import { usePartsRoutes } from "../../hooks"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { useHistory } from "react-router"
import { useWorkTaskId } from "@tm/context-distribution"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"


const MainContainer = styled(Stack)(({ theme }) => ({
    marginBlock: theme.spacing(0.5),
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(2),
    backgroundColor: theme.overwrites?.components?.partListV2?.backgroundColor,

    [`.${selectClasses.select}`]: {
        minWidth: 100
    }
}))

const HintContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: theme.spacing(0.5),
    lineHeight: "14px"
}))

const DisabledMenuItem = styled(MenuItem)({
    "&.Mui-disabled": {
        [`.${typographyClasses.root}`]: {
            color: "inherit"
        }
    }
})
DisabledMenuItem.defaultProps = {
    disabled: true
}

function OeReplacementChainComponent({ manufacturers }: { manufacturers: OeReplacementManufacturer[] }) {

    const { translate } = useLocalization()
    const query = useDirectSearchListParamsContext(x => x.startParams.query)
    const routes = usePartsRoutes()
    const history = useHistory()
    const workTaskId = useWorkTaskId()

    const [selectedManufacturerId, setSelectedManufacturerId] = useState(0)

    const selectedManufacturer = useMemo(() => manufacturers.find(x => x.id === selectedManufacturerId), [selectedManufacturerId, manufacturers])

    const replacementNumbers = useMemo(() => {
        if (!selectedManufacturer) {
            return []
        }
        return selectedManufacturer.replacementNumbers
    }, [selectedManufacturerId, manufacturers])

    const selectedReplacementNumber = useMemo(() =>
        replacementNumbers.find(num => normalizeReplacementNumber(num) === normalizeReplacementNumber(query)) ?? ""
        , [replacementNumbers])

    useEffect(() => {
        const manufacturer =
            manufacturers.find((x) => containsReplacementNumber(x.replacementNumbers, query)) ?? // If not select the first manufacturer which has a replacement number matching the query
            manufacturers.first() // At least select the first manufacturer

        return setSelectedManufacturerId(manufacturer?.id ?? 0)
    }, [manufacturers, query])

    function handleChangeManufacturer(event: SelectChangeEvent<unknown>) {
        setSelectedManufacturerId(selectedManufacturer?.id ?? 0)
    }

    function handleChangeNumber(event: SelectChangeEvent<unknown>) {
        const route = routes.directSearch?.list
        if (!route || !workTaskId) {
            return
        }
        const baseUrl = renderRoute(route, { workTaskId: encodeUniqueId(workTaskId) })
        const searchUrl = `${baseUrl}?query=${encodeURIComponent(event.target.value as number)}&searchFilter=${SearchFilterTypes.OeReference}`

        history.push(searchUrl)
    }

    function renderManufacturerDisplay() {
        if (!selectedManufacturer) {
            return translate(71)
        }
        return selectedManufacturer.name
    }

    function renderReplacementNumberDisplay() {
        if (!selectedReplacementNumber) {
            return translate(758)
        }
        return selectedReplacementNumber
    }


    return (
        <MainContainer>

            <Typography variant="body3">{translate(3004)}</Typography>

            <Select variant="outlined" value={selectedManufacturerId} onChange={handleChangeManufacturer} displayEmpty renderValue={renderManufacturerDisplay}>
                {manufacturers.map(manufacturer =>
                    <MenuItem value={manufacturer.id} key={manufacturer.name}>
                        {manufacturer.name}
                    </MenuItem>
                )}
            </Select>

            <Select variant="outlined" value={selectedReplacementNumber} onChange={handleChangeNumber} displayEmpty renderValue={renderReplacementNumberDisplay}>
                <DisabledMenuItem>
                    <Typography variant="body3">{translate(3006)}</Typography>
                </DisabledMenuItem>
                {replacementNumbers.map((replacementNumber, idx) =>
                    <MenuItem value={normalizeReplacementNumber(replacementNumber)} key={replacementNumber} sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
                        <Icon name="arrow-long-left-up" sx={idx === 0 ? { visibility: "hidden" } : undefined} />
                        {replacementNumber}
                    </MenuItem>
                )}
                <DisabledMenuItem>
                    <Typography variant="body3">{translate(3005)}</Typography>
                </DisabledMenuItem>
            </Select>

            <HintContainer>
                <Icon name="info" size="14px" />
                <Stack>
                    <Box>
                        <Typography variant="label">
                            {translate(12440)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="label">
                            {translate(12441)}
                        </Typography>
                    </Box>
                </Stack>
            </HintContainer>

        </MainContainer>
    )
}

export function OeReplacementChain() {
    const { replacementData } = useOeReplacementContext()

    if (!replacementData?.length) {
        return null
    }

    return <OeReplacementChainComponent manufacturers={replacementData} />

}